<template>
  <div v-if="currentUser" class="text-theme-500 flex flex-col gap-4 mx-4">
    <div class="flex text-2xl font-extrabold items-center justify-between gap-2 sticky bg-theme-500 text-white p-2 -mx-4 top-0">
      <button v-if="isEditing" class="btn btn-sm btn-theme-muted" @click="onCancelEdit"><i class="fas fa-x mr-1" />Cancel</button>
      <div v-else></div>
      <div class="flex gap-2">
        <i class="fas fa-user" />
        <div>{{ currentUser.name }}</div>
      </div>
      <button v-if="!isEditing" class="btn btn-sm btn-theme font-normal" @click="isEditing = true">Edit</button>
      <button v-else class="btn btn-sm btn-theme" :disabled="!isProfileValid" @click="onUpdateProfileClicked"><i class="fas fa-save mr-1" />Save</button>
    </div>
    <div class="flex flex-col">
      <div class="text-2xl font-bold">Username</div>
      <input class="form-control" disabled v-model="currentUser.preferred_username" />
    </div>
    <div class="flex flex-col">
      <div class="text-2xl font-bold">Email</div>
      <input class="form-control" :disabled="!isEditing" v-model="email" />
    </div>
    <div class="flex flex-col">
      <div class="text-2xl font-bold">First Name</div>
      <input class="form-control" :disabled="!isEditing" v-model="firstName" />
    </div>
    <div class="flex flex-col">
      <div class="text-2xl font-bold">Last Name</div>
      <input class="form-control" :disabled="!isEditing" v-model="lastName" />
    </div>
    <div class="flex flex-col">
      <div class="text-2xl font-bold">Roles</div>
      <div v-for="role in sortedRoles" :key="role" class="ml-4">{{ role }}</div>
    </div>
  </div>
</template>

<script>
import FullScreenLoadingMixin from '@/mixins/FullScreenLoadingMixin'
import _ from 'lodash'

export default {
  name: 'user-profile',
  mixins: [
    FullScreenLoadingMixin
  ],
  data () {
    return {
      isEditing: false,
      email: '',
      firstName: '',
      lastName: ''
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.currentUser
    },
    sortedRoles () {
      return _.sortBy(this.currentUser.roles)
    },
    isProfileValid () {
      return (this.email.length > 3 && this.firstName.length > 0 && this.lastName.length > 0)
    }
  },
  watch: {
    currentUser () {
      this.setModels()
    }
  },
  methods: {
    setModels () {
      if (this.currentUser === null) {
        this.email = ''
        this.firstName = ''
        this.lastName = ''
        return
      }

      this.isEditing = false
      this.email = this.currentUser.email
      this.firstName = this.currentUser.given_name
      this.lastName = this.currentUser.family_name
    },
    onCancelEdit () {
      this.setModels()
    },
    async onUpdateProfileClicked () {
      if (!this.isProfileValid) return

      try {
        this.showLoading('Updating Profile', null)
        const payload = { email: this.email, firstName: this.firstName, lastName: this.lastName }
        await this.$store.dispatch('updateProfile', payload)

        const self = this
        this.$store.dispatch('tokenCheck', this.$store.state.tokenWrapper.refresh_token).then(response => {
          self.$store.commit('setTokenWrapper', response.data)
        }).catch(error => {
          console.error('Error refreshing the current user', error)
        })

        this.isEditing = false
        this.$swal.fire({
          icon: 'success',
          title: 'Profile Updated',
          text: 'Your user profile was successfully updated.',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      } catch (error) {
        console.error('Error updating profile', error)
        this.$swal.fire({
          icon: 'error',
          title: 'Profile Update Failed',
          text: 'An error occurred while updating your user profile and your profile was not updated.',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }
    }
  },
  mounted () {
    this.setModels()
  }
}
</script>
