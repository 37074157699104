<script>
export default {
  name: 'UserRolesMixin',
  computed: {
    currentUser () {
      return this.$store.state.currentUser
    },
    userRoles () {
      if (this.currentUser === null) return []
      if (typeof this.currentUser.roles === 'undefined') return []
      if (this.currentUser.roles === null) return []
      return this.currentUser.roles
    }
  }
}
</script>
