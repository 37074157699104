<template>
  <template v-for="route in routes" :key="route.name">
    <router-link v-if="showRoute(route)" :to="{name: route.name, query: route.getQuery()}" class="p-2 rounded flex items-center w-full hover:no-underline hover:text-white hover:bg-theme-300">
      <i :class="['fas mr-2', route.icon]"></i>
      <span class="text-sm">{{route.display}}</span>
    </router-link>
    <div class="flex flex-col gap-1 -mt-2 mr-2">
      <VMenu v-for="childRoute in route.getChileRoutes()" :key="childRoute.id" :delay="{ show: 200, hide: 0 }">
        <router-link :to="childRoute.routeTo" class="ml-3 p-1 rounded flex flex-col w-full text-xs hover:no-underline hover:text-white hover:bg-theme-300">
          <span class="truncate">{{ childRoute.title }}</span>
          <span>{{ childRoute.subtitle }}</span>
        </router-link>

        <template #popper>
          <component :is="childRoute.popoverComponent" :value="childRoute.popoverModel"></component>
        </template>
      </VMenu>
    </div>
  </template>
</template>

<script>
import UserRolesMixin from '@/mixins/UserRolesMixin'
import SearchPreview from '@/components/SearchPreview'
import moment from 'moment'

export default {
  name: 'search-child-menu',
  mixins: [UserRolesMixin],
  components: {
    SearchPreview
  },
  data () {
    return {
      routes: [
        {
          name: 'UnifiedDatasets',
          icon: 'fa-search',
          display: 'Datasets',
          getQuery: () => { return null },
          passesRoleCheck: () => { return true },
          getChileRoutes: () => { return this.datasetSearches }
        },
        {
          name: 'Filesets',
          icon: 'fa-folder',
          display: 'Filesets',
          getQuery: () => { return this.filesetQuery },
          passesRoleCheck: () => { return true },
          getChileRoutes: () => { return [] }
        },
        {
          name: 'JupyterNotebooks',
          icon: 'fa-book',
          display: 'Analysis Library',
          getQuery: () => { return null },
          passesRoleCheck: () => { return true },
          getChileRoutes: () => { return [] }
        },
        {
          name: 'DataHub',
          icon: 'fa-database',
          display: 'Metadata Discovery',
          getQuery: () => { return null },
          passesRoleCheck: () => { return this.checkRole(this.dataHub) },
          getChileRoutes: () => { return [] }
        },
        {
          name: 'Kibana',
          icon: 'fa-pencil',
          display: 'Reporting',
          getQuery: () => { return null },
          passesRoleCheck: () => { return this.checkRole(this.kibana) },
          getChileRoutes: () => { return [] }
        }
      ]
    }
  },
  computed: {
    dataHub () {
      return this.$store.state.dataHub
    },
    kibana () {
      return this.$store.state.kibana
    },
    filesetQuery () {
      return this.$store.state.filesetQueryParams
    },
    disabledLinks () {
      return this.$store.state.disabledLinks
    },
    datasetSearches () {
      return this.$store.state.datasetSearches.map(search => {
        return {
          id: search.id,
          title: search.datasetDescription.datasetId,
          subtitle: moment(search.timestamp).format('h:mm a'),
          routeTo: {
            name: 'SearchResults',
            params: { searchId: search.id }
          },
          popoverComponent: 'search-preview',
          popoverModel: search
        }
      })
    }
  },
  methods: {
    showRoute (route) {
      return !this.disabledLinks.includes(route.name) && route.passesRoleCheck()
    },
    checkRole (viewSettings) {
      if (viewSettings === null) return false
      if (this.currentUser === null) return false
      if (viewSettings.role === '*') return true
      return this.userRoles.includes(viewSettings.role)
    }
  }
}
</script>
