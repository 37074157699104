<template>
  <div class="w-full h-full">
    <div class="flex flex-col max-h-4/5 scroll-auto gap-1">
      <div v-for="layer in availableLayers" :key="layer.name" class="flex w-full justify-between items-center bg-gray-800 p-2 rounded">
        <div class="flex w-full gap-1 items-center">
          <input
            type="color"
            class="w-6 h-7 mr-2 bg-transparent border-0 rounded p-0 cursor-pointer"
            :value="layer.color"
            @input="colorChanged($event, layer)"
          />
          <div class="flex flex-col">
            <div class="flex">{{ layer.name }}</div>
            <div v-if="layer.isDefault" class="text-xs italic">default path</div>
          </div>
        </div>
        <toggle-switch v-model="layer.isActive"></toggle-switch>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import ToggleSwitch from '@/components/ToggleSwitch'

export default {
  name: 'path-layer-configurator',
  emits: ['update:modelValue'],
  components: {
    ToggleSwitch
  },
  props: {
    modelValue: { type: Object, required: true }
  },
  data () {
    return {
      layerModel: this.modelValue
    }
  },
  computed: {
    availableLayers () {
      return _.sortBy(this.layerModel, 'name')
    }
  },
  watch: {
    modelValue () {
      this.layerModel = this.modelValue
    },
    layerModel: {
      handler () {
        this.$emit('update:modelValue', this.layerModel)
      },
      deep: true
    }
  },
  methods: {
    colorChanged: _.debounce(async function (event, layer) {
      layer.color = event.target.value
      this.$emit('update:modelValue', this.layerModel)
    }, 500)
  }
}
</script>
