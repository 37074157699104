<template>
  <div class="flex items-center h-screen w-full justify-center">
    <iframe v-if="showView" :src="viewSettings.url" class="w-full h-full"></iframe>
    <div v-else>Page does not exist</div>
  </div>
</template>

<script>
import UserRolesMixin from '@/mixins/UserRolesMixin'

export default {
  name: 'external-view',
  mixins: [UserRolesMixin],
  props: {
    view: { type: String, required: true }
  },
  computed: {
    viewSettings () {
      return this.$store.state[this.view]
    },
    showView () {
      if (this.viewSettings === null) return false
      if (this.currentUser === null) return false
      if (this.viewSettings.role === '*') return true
      return this.userRoles.includes(this.viewSettings.role)
    }
  }
}
</script>
