<template>
  <div class="flex justify-between items-center">
    <div :class="{ 'text-gray-400': currentLocation === null}">{{ name }}</div>
    <div v-if="currentLocation" class="flex gap-2 items-center">
      <i v-if="item.path.availableLayers.length > 1" class="fas fa-layer-group text-theme-500 hover:text-theme-300 cursor-pointer" @click="showLayerConfigurator = true" />
      <loading v-if="isPathLoading" :text="`${pathLoadingPercent}%`"></loading>
      <i v-else :class="['fas fa-route hover:text-theme-300 cursor-pointer', { 'text-theme-300': isPathVisible  }, { 'text-theme-500': !isPathVisible }]" :title="isPathVisible ? 'Hide Path' : 'Show Path'" @click="onToggleItemPath"></i>
      <i class="fas fa-location-dot text-theme-500 hover:text-theme-300 cursor-pointer" @click="locateItem"></i>
    </div>
    <VMenu v-else :delay="{ show: 200, hide: 0 }">
      <i class="fas fa-warning text-amber-500 cursor-help" />

      <template #popper>
        <div class="p-2 max-w-72">{{ name }} was in your original search; however it is not currently on the map. Adjust the time scrubber or search criteria.</div>
      </template>
    </VMenu>
  </div>
  <div v-show="false" ref="itemPopup">
    <map-item-popup
      :title="name"
      :subtitle="subtitle"
      :location="displayFriendlyCoordinates"
      :result="displayResult"
      :header-color="stalenessColor">
    </map-item-popup>
  </div>
  <modal v-if="showLayerConfigurator" bg-class="bg-black" class="text-white">
    <template v-slot:header>
      <h1 class="text-2xl">Path Layers</h1>
      <div class="text-xs italic">At least 1 path layer must be active. To remove all paths, toggle the path icon in the {{ search.datasetDescription.mapSupport.groupItemField }}s panel.</div>
    </template>
    <template v-slot:body>
      <path-layer-configurator :modelValue="item.path.availableLayers" @update:modelValue="updateAvailableLayers"></path-layer-configurator>
    </template>
    <template v-slot:footer>
      <button class="btn btn-theme" href="#" @click="showLayerConfigurator = false" :disabled="allLayersInactive"><i class="fas fa-check" /> Done</button>
    </template>
  </modal>
</template>

<script>
import { shallowRef } from 'vue'
import _ from 'lodash'
import L from 'leaflet'
import moment from 'moment'
import DisplayFormatMixin from '@/mixins/DisplayFormatMixin'
import Loading from '@/components/Loading'
import MapItemPopup from '@/components/map/MapItemPopup'
import Modal from '@/components/Modal'
import PathLayerConfigurator from '@/components/map/PathLayerConfigurator'
import SearchQueryMixin from '@/mixins/SearchQueryMixin'

export default {
  name: 'map-item',
  mixins: [
    DisplayFormatMixin,
    SearchQueryMixin
  ],
  components: {
    Loading,
    MapItemPopup,
    Modal,
    PathLayerConfigurator
  },
  emits: ['set-path-tooltip', 'set-end-time'],
  props: {
    name: { type: String, required: true },
    item: { type: Object, required: true },
    scrubTimeMs: { type: Number, required: false },
    map: { type: Object, required: false }
  },
  data () {
    return {
      pathTimeout: null,
      showLayerConfigurator: false,
      allLayersInactive: false
    }
  },
  computed: {
    pathLoadingPercent () {
      return this.item.path.loadingPercent
    },
    popupInfo () {
      return `${this.name} ${this.subtitle} ${this.displayFriendlyCoordinates} ${JSON.stringify(this.displayResult)} ${this.stalenessColor}`
    },
    endTimeMs () {
      return this.search?.mapResults?.endTimeMs ?? null
    },
    isPathLoading () {
      return this.item.path.isLoading
    },
    itemMarker () {
      return this.item.mapMarker
    },
    isPathVisible () {
      return this.item.path.isVisible
    },
    pathLayer () {
      return this.item.path.layer
    },
    defaultLayer () {
      const layers = this.item.path.availableLayers.filter(layer => layer.isDefault)
      if (layers.length !== 1) return null
      return layers[0]
    },
    pathPoints () {
      if (this.scrubTimeMs === null || this.defaultLayer.points === null) return this.defaultLayer.points
      return this.defaultLayer.points.filter(p => p.timeMs <= this.scrubTimeMs)
    },
    divIcon () {
      const faMapIcon = this.search.datasetDescription.faMapIcon
      if (faMapIcon === null) return null
      return L.divIcon({
        html: `<i class="fas ${faMapIcon} text-2xl text-${this.stalenessColor}"></i>`,
        iconSize: [27, 24],
        className: 'divIcon'
      })
    },
    stalenessColor () {
      const notStaleColor = 'theme-600'
      if (this.search.filters.timeRange === null || this.endTimeMs === null || this.timeMs === null) {
        return notStaleColor
      }

      const endTime = moment(this.endTimeMs)
      const itemTime = moment(this.timeMs)
      const hours = moment.duration(endTime.diff(itemTime)).asHours()
      if (hours < this.$store.state.mapSupport.staleWarningHours) {
        return notStaleColor
      } else if (hours < this.$store.state.mapSupport.staleErrorHours) {
        return 'amber-600'
      }
      return 'rose-600'
    },
    subtitle () {
      if (this.item.currentResult === null) return null
      const itemTemporalField = this.search.datasetDescription.mapSupport.itemTemporalField

      if (this.endTimeMs !== null && this.timeMs !== null) {
        return moment.utc(this.timeMs).from(moment(this.endTimeMs))
      } else {
        return this.getValue(this.item.currentResult, _.find(this.columns, ['name', itemTemporalField]), false)
      }
    },
    timeMs () {
      if (this.item.currentResult === null) return null
      return this.getItemTemporalMs(this.item.currentResult)
    },
    displayFriendlyCoordinates () {
      if (this.currentLocation === null) return '-'
      return `${(Math.round(this.currentLocation[0] * 100) / 100).toFixed(2)}, ${(Math.round(this.currentLocation[1] * 100) / 100).toFixed(2)}`
    },
    displayResult () {
      if (this.item.currentResult === null) return null
      const ret = {}
      for (const column of this.columns) {
        ret[column.display] = this.getValue(this.item.currentResult, column, false)
      }
      return ret
    },
    currentLocation () {
      if (this.item.currentResult === null) {
        // if we have no path loaded or the path is empty, set current location, we have no current location
        if (this.pathPoints === null || this.pathPoints.length === 0) return null
        return this.pathPoints[0].latLon
      }

      const result = this.item.currentResult
      const itemLocationField = this.search.datasetDescription.mapSupport.itemLocationField
      const locationFields = _.find(this.search.datasetDescription.schema, ['name', itemLocationField ?? '-'])
      return [result[locationFields.combined.fields[0]], result[locationFields.combined.fields[1]]]
    }
  },
  watch: {
    popupInfo () {
      if (this.itemMarker === null) return
      const self = this
      this.$nextTick(() => {
        self.itemMarker.getPopup().setContent(self.$refs.itemPopup.innerHTML)
      })
    },
    map () {
      this.addMapMarker()
      this.updatePathLayerVisibility()
    },
    itemMarker () {
      this.addMapMarker()
    },
    isPathVisible () {
      this.updatePathLayerVisibility()
    },
    currentLocation () {
      // both are null, do nothing
      if (this.itemMarker === null && this.currentLocation === null) return

      if (this.itemMarker === null) {
        // we have a location but no marker, create it (creating it will end up adding it)
        this.createMapMarker()
      } else if (this.currentLocation === null) {
        // we have a marker but no location, remove it
        this.itemMarker.remove()
      } else {
        // the last possible combo is we have both, set the marker and ensure its on map
        this.itemMarker.setLatLng(this.wrapLatLon(this.currentLocation))
        this.addMapMarker()
      }
    },
    scrubTimeMs () {
      this.createPathLayer()
    },
    divIcon () {
      if (this.itemMarker === null) return
      this.itemMarker.setIcon(this.divIcon)
    },
    pathPoints () {
      this.createPathLayer()
    }
  },
  methods: {
    updateAvailableLayers (availableLayers) {
      const searchId = this.searchId
      this.$store.commit('setAvailableLayers', { searchId, availableLayers, itemKey: this.name })
      this.allLayersInactive = _.every(this.item.path.availableLayers, ['isActive', false])
      this.createPathLayer()
    },
    locateItem () {
      if (this.map === null || this.itemMarker === null) return
      this.map.setView(this.itemMarker.getLatLng(), 11)
      this.itemMarker.openPopup()
    },
    onToggleItemPath () {
      const searchId = this.searchId
      const shouldShow = !this.isPathVisible
      this.$store.commit('setSearchMapItemPathVisible', { searchId, itemKey: this.name, isVisible: shouldShow })
      if (shouldShow) {
        // if we are loading the path already, do nothing
        if (this.isPathLoading) return
        if (this.pathLoadingPercent === 0) {
          this.fetchItemPath()
        }
      }
    },
    getItemTemporalMs (result) {
      const itemTemporalField = this.search.datasetDescription.mapSupport.itemTemporalField
      const temporalColumn = _.find(this.columns, ['name', itemTemporalField])
      if ((temporalColumn.type !== 'TIMESTAMP_SECONDS' && temporalColumn.type !== 'TIMESTAMP_MILLS')) {
        console.warn('Error getting temporatl time in Ms')
        return null
      }

      let value = result[itemTemporalField]
      if (temporalColumn.type === 'TIMESTAMP_SECONDS') {
        // this field is in seconds, convert to ms
        value = value * 1000
      }
      return value
    },
    addMapMarker () {
      if (this.map === null) return
      if (this.itemMarker === null) return
      if (this.currentLocation === null) return

      this.itemMarker.addTo(this.map)
    },
    updatePathLayerVisibility () {
      if (this.map === null) return

      if (this.isPathVisible) {
        this.pathLayer.addTo(this.map)
        this.enablePathLayerInterctivity()
      } else {
        this.pathLayer.remove()
      }
    },
    async fetchItemPath () {
      if (this.isPathLoading || this.pathLoadingPercent !== 0) return

      const groupItemField = this.search.datasetDescription.mapSupport.groupItemField
      const searchId = this.searchId
      this.$store.commit('setSearchMapItemPathLoadingPercent', { searchId, itemKey: this.name, loadingPercent: 0 })
      this.$store.commit('setSearchMapItemPathIsLoading', { searchId, itemKey: this.name, isLoading: true })
      const searchData = JSON.parse(JSON.stringify(this.searchPayload))
      const maxResults = this.$store.state.mapSupport.maxPointResults
      searchData.paging.offset = 0
      searchData.paging.max = maxResults

      if (typeof _.find(this.search.filters.dynamic, { name: groupItemField }) === 'undefined') {
        searchData.filters.push({
          name: groupItemField,
          operator: '=',
          value: this.name
        })
      }

      const pathColumns = this.item.path.availableLayers.flatMap(layer => layer.fields)
      pathColumns.push(this.search.datasetDescription.mapSupport.itemTemporalField)
      searchData.columns = pathColumns
      const dispatchData = { category: this.search.datasetDescription.category, datasetId: this.search.datasetDescription.datasetId, searchData }

      try {
        const countDispatchData = JSON.parse(JSON.stringify(dispatchData))
        delete countDispatchData.searchData.paging
        const countResponse = await this.$store.dispatch('fetchMapPathCount', countDispatchData)
        const totalPoints = countResponse.data.totalResults

        if ((totalPoints / maxResults) > this.$store.state.mapSupport.maxPathCallsNoWarning) {
          const result = await this.$swal({
            title: 'A Lot of Data',
            html: `The path data for ${this.name} is very large and may result in a long wait time or causing your browser to crash. You can try to load the path anyways. If not, you can focus your search query to a smaller result set and try again.<br /><br />Do you want to try to load this path anyways?`,
            icon: 'question',
            showCancelButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: 'Yes, Try Loading Path'
          })

          if (!result.isConfirmed) {
            return
          }
        }

        const points = {}
        const layerNameToColumnsLookup = {}
        this.item.path.availableLayers.forEach(layer => {
          layerNameToColumnsLookup[layer.name] = layer.fields
          points[layer.name] = []
        })
        const layerNames = Object.keys(layerNameToColumnsLookup)

        let pointsLoaded = 0
        let previousTimeMs = Number.MAX_SAFE_INTEGER
        while (true) {
          const response = await this.$store.dispatch('fetchMapPath', dispatchData)
          const responseResultCount = response.data.results.length
          let currentIndex = -1
          for (const result of response.data.results) {
            currentIndex += 1
            const timeMs = this.getItemTemporalMs(result)
            // ignore points less than settings apart (except for teh last item in the response - always process that)
            if (previousTimeMs - timeMs < this.$store.state.mapSupport.pathPointIntervalMs && (currentIndex + 1) !== responseResultCount) continue

            for (const layerName of layerNames) {
              const lat = result[layerNameToColumnsLookup[layerName][0]]
              const lng = result[layerNameToColumnsLookup[layerName][1]]
              const latLon = [
                lat,
                lng
              ]
              points[layerName].push({
                timeMs,
                latLon
              })
            }

            previousTimeMs = timeMs
          }

          pointsLoaded += responseResultCount
          const percent = ((pointsLoaded / totalPoints) * 100).toFixed(2)
          this.$store.commit('setSearchMapItemPathLoadingPercent', { searchId, itemKey: this.name, loadingPercent: percent })
          dispatchData.searchData.paging.offset += maxResults
          if (responseResultCount < maxResults) break
        }

        this.$store.commit('setSearchMapItemPathLoadingPercent', { searchId, itemKey: this.name, loadingPercent: 100 })
        this.$store.commit('setSearchMapItemPathPoints', { searchId, itemKey: this.name, points })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Path Loading Failed',
          text: `An error occurred while loading the path for ${this.name}. Please try again.`,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        console.error('Error loading item path', error)
      } finally {
        this.$store.commit('setSearchMapItemPathIsLoading', { searchId, itemKey: this.name, isLoading: false })
      }
    },
    wrapLatLon (latLon) {
      return [latLon[0], L.Util.wrapNum(latLon[1], [0, 360], true)]
    },
    createPathLayer () {
      // remove all paths from the path layer
      this.pathLayer.clearLayers()

      /**
       * TODO
       * loop over all available layers...
       *    X clear layer
       *    X add in all lines from the layer's points
       *    X add layer to the main path layer IF if is active
       *    enable path interactivity (?)
       */
      const self = this
      let currentEndTimeMs = this.scrubTimeMs === null ? this.endTimeMs : this.scrubTimeMs
      if (currentEndTimeMs === null) currentEndTimeMs = Number.MAX_VALUE
      this.item.path.availableLayers.forEach(availableLayer => {
        const layerGroup = availableLayer.layer
        layerGroup.clearLayers()
        if (availableLayer.points !== null && availableLayer.points.length > 1) {
          let currentLinePoints = []
          let previousPoint = null
          for (const point of availableLayer.points) {
            if (point.timeMs > currentEndTimeMs) continue
            if (previousPoint === null) previousPoint = point // start the drawing at the first point inside scrubtime

            // if we have a gap of over our limit, add a dotted line between the 2 points
            if (previousPoint.timeMs - point.timeMs > self.$store.state.mapSupport.dataGapTimeMsThreshold) {
              // add segment
              if (currentLinePoints.length === 1) {
                currentLinePoints.push(currentLinePoints[0])
              }
              layerGroup.addLayer(L.polyline(currentLinePoints, { color: availableLayer.color }))

              // created dotted segment between last and current point
              layerGroup.addLayer(L.polyline([self.wrapLatLon(previousPoint.latLon), self.wrapLatLon(point.latLon)], { color: 'red', dashArray: '5, 10', dashOffset: '0' }))

              // reset currentLinePoints
              currentLinePoints = []
            }

            currentLinePoints.push(self.wrapLatLon(point.latLon))
            previousPoint = point
          }

          if (currentLinePoints.length === 1) {
            currentLinePoints.push(currentLinePoints[0])
          }
          layerGroup.addLayer(L.polyline(currentLinePoints, { color: availableLayer.color }))
        }

        if (availableLayer.isActive) {
          self.item.path.layer.addLayer(layerGroup)
        }
      })

      this.enablePathLayerInterctivity()
    },
    enablePathLayerInterctivity () {
      const self = this

      const showLayerName = this.item.path.availableLayers.length > 1
      this.item.path.availableLayers.forEach(availableLayer => {
        availableLayer.layer.eachLayer(layer => {
          // do not attach mouse events to dashed lines
          if (typeof layer.options.dashArray !== 'undefined' && layer.options.dashArray !== null) return

          layer.on('mouseover', e => {
            const pathPoint = self.findClosestPoint(availableLayer.points, e.latlng)
            if (pathPoint === null) return
            let text = moment(pathPoint.timeMs).utc().format('MM/DD/YYYY, hh:mm:ss A')
            if (showLayerName) {
              text = `${availableLayer.name}\n${text}`
            }
            const pathHoverTooltip = {
              text,
              top: e.containerPoint.y,
              left: e.containerPoint.x
            }

            if (self.pathTimeout !== null) {
              window.clearTimeout(self.pathTimeout)
              self.pathTimeout = null
            }
            self.$emit('set-path-tooltip', pathHoverTooltip)
          })

          layer.on('mouseout', e => {
            self.pathTimeout = window.setTimeout(() => {
              self.$emit('set-path-tooltip', null)
              self.pathTimeout = null
            }, 750)
          })

          if (self.endTimeMs === null) return // no end time, don't allow click scrubbing
          layer.on('click', e => {
            const pathPoint = self.findClosestPoint(availableLayer.points, e.latlng)
            if (pathPoint === null) return
            self.$emit('set-end-time', pathPoint.timeMs)
            self.$emit('set-path-tooltip', null)
          })
        })
      })
    },
    findClosestPoint (points, latLng) {
      if (points === null || points.length === 0) return null

      return _.minBy(points, p => {
        return L.latLng(p.latLon).distanceTo(latLng)
      })
    },
    createMapMarker () {
      if (this.currentLocation === null) return
      if (this.itemMarker !== null) return

      let mapMarker = null
      const markerLocation = this.wrapLatLon(this.currentLocation)
      if (this.divIcon === null) {
        mapMarker = L.marker(markerLocation)
      } else {
        mapMarker = L.marker(markerLocation, { icon: this.divIcon })
      }
      mapMarker.bindPopup(this.$refs.itemPopup.innerHTML)
      const searchId = this.searchId
      const itemKey = this.name
      this.$store.commit('setSearchMapItemMarker', { searchId, itemKey, mapMarker: shallowRef(mapMarker) })
    }
  },
  mounted () {
    if (this.itemMarker === null) {
      this.createMapMarker()
    } else {
      this.addMapMarker()
    }

    this.updatePathLayerVisibility()
  },
  unmounted () {
    if (this.itemMarker !== null) {
      this.itemMarker.remove()
    }

    this.pathLayer.remove()
  }
}
</script>
