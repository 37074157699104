<template>
  <div>
    <router-view></router-view>
    <div v-if="isRootDatasets" class="px-2">
      <strong>Dataset</strong>
      <unified-dataset-selector
          class="w-full mr-1"
          v-model="selectedDataset"
          :disabled="isLoadingDescription">
      </unified-dataset-selector>
      <div v-if="selectedDataset" class="text-xs flex flex-col">
        <div v-if="selectedDataset.model.category.description">{{selectedDataset.model.category.description}}</div>
        <div v-if="selectedDataset.model.dataset.description">{{selectedDataset.model.dataset.description}}</div>
      </div>
      <template v-if="selectedDatasetDescription && datasetFilters">
        <div class="mt-4 flex flex-col items-center gap-2">

          <div class="flex flex-col w-full">
            <div class="font-bold self-start">Saved Searches</div>
            <small>Optional: Select a saved search from this dataset to quickly pre-fill your filters.</small>
            <button class="btn btn-theme-muted w-full border-dashed" @click="isSelectingSavedSearch = true"><i class="fa-regular fa-folder-open mr-1" />View Saved Searches</button>
          </div>

          <div class="mt-2 font-bold self-start -mb-2">Filters</div>
          <div v-if="typeof datasetFilters.timeRange !== 'undefined'" class="flex flex-col w-full">
            <div class="font-bold text-xs">Temporal</div>
            <div class="input-group cursor-pointer" @click="showTemporalModal = true">
              <div class="input-group-text w-42px" title="datetime range"><i class="fas fa-clock" /></div>
              <span v-if="datasetFilters.timeRange === null" class="form-control group-text text-gray-500">no datetime range filter defined</span>
              <span v-else class="form-control group-text">{{datasetFilters.timeRange.textual}}</span>
            </div>
          </div>
          <div v-if="typeof datasetFilters.geospatial !== 'undefined'" class="flex flex-col w-full">
            <div class="font-bold text-xs">Geospatial</div>
            <div class="input-group cursor-pointer" @click="showGeospatialModal = true">
              <div class="input-group-text w-42px" title="geospatial"><i class="fas fa-globe" /></div>
              <span v-if="datasetFilters.geospatial === null" class="form-control group-text text-gray-500">no geospatial filter defined</span>
              <span v-else class="form-control group-text">{{datasetFilters.geospatial.textual}}</span>
            </div>
          </div>
          <div v-for="filter in datasetFilters.fieldFilters" :key="filter.name" class="flex flex-col w-full">
            <div class="flex justify-between text-xs">
              <div class="flex flex-col">
                <div class="font-bold">{{ filter.display }}</div>
                <div v-if="filter.description !== null && filter.description.length > 0">{{ filter.description }}</div>
              </div>
              <div v-if="filter.required" class="font-bold text-red-500 self-end">Required</div>
            </div>
            <div class="input-group" :title="filter.display">
              <div class="input-group-text w-42px"><i class="fas fa-filter" /></div>
              <input :type="filter.type.toLowerCase() === 'number' ? 'number' : 'text'" class="form-control group-text" :placeholder="`no ${filter.display.toLowerCase()} filter defined`" :disabled="filter.preFilled" v-model="filter.value" />
              <i v-if="!filter.required" class="fas fa-x mx-2 self-center text-red-500 cursor-pointer hover:text-red-300" @click="removeAdditionalFilter(filter)" />
            </div>
          </div>
          <div v-if="datasetFilters.additionalFilters && datasetFilters.additionalFilters.length > 0" class="flex flex-col w-full items-center">
            <v-select v-if="isAddingFilter" :options="datasetFilters.additionalFilters" v-model="additionalFilter" placeholder="select a filter" :get-option-label="filter => filter.display" :append-to-body="true" class="w-full"></v-select>
            <button v-else class="btn btn-theme-muted w-full border-dashed" @click="isAddingFilter = true"><i class="fas fa-plus mr-1" />Add Filter</button>
            <small v-if="selectedDatasetDescription.filterSupport.requiredOrFields" class="text-gray-500">To perform a search, you must add one of the following filters: {{ requiredOrFieldsToSearch }}.</small>
          </div>
          <div class="flex gap-2 justify-end items-end mt-2 w-full">
            <button v-if="selectedDatasetDescription.features.includes('SAMPLE')" class="btn btn-theme-muted" @click="performSearch(true)"><i class="fas fa-table mr-1" />Sample</button>
            <button class="btn btn-theme" :disabled="!isSearchRequirementsFulfilled" @click="performSearch(false)"><i class="fas fa-search mr-1" />Search</button>
          </div>
        </div>
      </template>
      <loading v-if="isLoadingDescription" text="Loading dataset filters..."></loading>
      <retryable-error v-if="failureLoadingDescription" text="Failed to load dataset filters" @retry="onRetryLoadDatasetDescriptions(selectedDataset.model.category, selectedDataset.model.dataset)"></retryable-error>

      <!-- temporal filter modal !-->
      <modal v-if="showTemporalModal">
        <template v-slot:header>
          <h1 class="text-2xl">Select Date and Time Range</h1>
          <h3>All times are in UTC</h3>
        </template>
        <template v-slot:body>
          <date-range v-model="datasetFilters.timeRange"></date-range>
        </template>
        <template v-slot:footer>
          <button class="btn btn-theme" href="#" @click="showTemporalModal = false">Done</button>
        </template>
      </modal>

      <!-- geospatial filter modal !-->
      <modal v-if="showGeospatialModal">
        <template v-slot:header>
          <h1 class="text-2xl">Geospatial Filter</h1>
        </template>
        <template v-slot:body>
          <div class="w-full h-96">
            <map-polygons v-model="datasetFilters.geospatial"></map-polygons>
          </div>
        </template>
        <template v-slot:footer>
          <button class="btn btn-theme" href="#" @click="showGeospatialModal = false">Done</button>
        </template>
      </modal>

      <modal v-if="isSelectingSavedSearch">
        <template v-slot:header>
          <h1 class="text-2xl">Saved Searches</h1>
        </template>
        <template v-slot:body>
          <div class="w-full">
            <div v-if="savedSearches.length === 0" class="flex flex-col items-center text-gray-600">
              <i class="fas fa-magnifying-glass text-2xl font-bold" />
              <div class="text-2xl font-bold">No Saved Searches</div>
              <div class="text-center">You have no saved searches for this Dataset. After performing a search, you can save the search under the "Actions" menu. Once you save a search, they will appear here for you to manage.</div>
            </div>
            <template v-else>
              Select a saved search to quickly pre-fill your filters.
              <v-select placeholder="select a saved search" :options="savedSearches" v-model="selectedSavedSearch" :append-to-body="true" :get-option-label="filter => filter.queryName">
                <template #option="item">
                  <div class="flex justify-between items-center">
                    <div class="flex flex-col">
                      <div>{{ item.queryName }}</div>
                      <div class="text-sm">{{ formatSavedSearchTime(item) }}</div>
                    </div>
                  </div>
                </template>
              </v-select>
            </template>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex w-full justify-between">
            <div class="flex gap-1">
              <button class="btn btn-destructive" href="#" @click="deleteSavedSearch" :disabled="selectedSavedSearch === null"><i class="fas fa-trash mr-1" />Delete</button>
              <button class="btn btn-theme-muted" href="#" @click="isSelectingSavedSearch = false">Cancel</button>
            </div>
              <button class="btn btn-theme" href="#" @click="loadSavedSearch" :disabled="selectedSavedSearch === null"><i class="fas fa-check mr-1" />Load</button>
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import DateRange from '@/components/DateRange'
import DisplayFormatMixin from '@/mixins/DisplayFormatMixin'
import Loading from '@/components/Loading'
import MapPolygons from '@/components/MapPolygons'
import Modal from '@/components/Modal'
import RetryableError from '@/components/RetryableError'
import UnifiedDatasetSelector from '@/components/UnifiedDatasetSelector'
import _ from 'lodash'
import moment from 'moment'
import { SearchResultViewMode } from '@/utils/SearchResultViewMode.js'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'unified-datasets',
  components: {
    DateRange,
    Loading,
    MapPolygons,
    Modal,
    RetryableError,
    UnifiedDatasetSelector
  },
  mixins: [DisplayFormatMixin],
  data () {
    return {
      isLoadingDescription: false,
      failureLoadingDescription: false,
      showTemporalModal: false,
      showGeospatialModal: false,
      isAddingFilter: false,
      additionalFilter: null,
      isSelectingSavedSearch: false,
      selectedSavedSearch: null
    }
  },
  computed: {
    selectedDataset: {
      get () {
        return this.$store.state.selectedDataset
      },
      set (selectedDataset) {
        this.$store.commit('setSelectedDataset', selectedDataset)
      }
    },
    isRootDatasets () {
      return this.$route.name === 'UnifiedDatasets'
    },
    selectedDatasetDescription () {
      if (this.selectedDataset === null) return null
      const model = this.selectedDataset.model
      const descriptionId = `${model.category.id}/${model.dataset.id}`
      const description = this.$store.state.sourceDescriptions[descriptionId]
      if (typeof description === 'undefined') return null
      return description
    },
    datasetFilters () {
      return this.$store.state.datasetFilters
    },
    requiredOrFieldsToSearch () {
      if (this.selectedDatasetDescription === null) return ''
      const requiredOrFields = this.selectedDatasetDescription.filterSupport.requiredOrFields ?? []
      return requiredOrFields.join(', ')
    },
    isSearchRequirementsFulfilled () {
      if (this.selectedDatasetDescription === null) return false

      const requiredOrFields = this.selectedDatasetDescription.filterSupport.requiredOrFields
      const staticFilters = this.selectedDatasetDescription.filterSupport.staticFilters
      const fieldFilters = this.datasetFilters.fieldFilters
      if (requiredOrFields !== null) {
        // if any of the filters defined in required or fields are set, we're good to go
        if (requiredOrFields.includes('geospatial') && this.datasetFilters.geospatial !== null) {
          return true
        }

        if (requiredOrFields.includes('temporal') && this.datasetFilters.timeRange !== null) {
          return true
        }

        // lastly check the remaining field filters
        return _.some(fieldFilters, filter => {
          return (requiredOrFields.includes(filter.name) && filter.value !== null && filter.value.toString().trim().length > 0)
        })
      } else if (staticFilters !== null) {
        // good to go only if all required static filters are set
        return fieldFilters.filter(filter => {
          return filter.required && (filter.value === null || filter.value.toString().trim().length === 0)
        }).length === 0
      }

      // if a dataset has no filter requirements, user can search
      return true
    },
    savedSearches () {
      if (this.selectedDataset === null) return []

      const category = this.selectedDataset.model.category.id
      const datasetId = this.selectedDataset.model.dataset.id
      return this.$store.state.savedSearches.filter(search => {
        return search.datasetCategory === category && search.datasetId === datasetId
      })
    }
  },
  watch: {
    isRootDatasets () {
      if (!this.isRootDatasets) return
      this.setFilters()
    },
    isSelectingSavedSearch () {
      this.selectedSavedSearch = null
    },
    isAddingFilter () {
      this.additionalFilter = null
    },
    additionalFilter () {
      if (this.additionalFilter !== null) {
        this.datasetFilters.fieldFilters.push(this.additionalFilter)
        this.isAddingFilter = false
        this.datasetFilters.additionalFilters = this.datasetFilters.additionalFilters.filter(filter => filter.name !== this.additionalFilter.name)
      }
    },
    async selectedDataset () {
      this.isAddingFilter = false
      this.failureLoadingDescription = false
      if (this.selectedDataset === null) return
      if (this.selectedDatasetDescription !== null) {
        // have the description, set the filters
        this.setFilters()
        return
      }

      const model = this.selectedDataset.model
      await this.fetchDatasetDescription(model.category, model.dataset)
      this.setFilters()
    }
  },
  methods: {
    formatSavedSearchTime (search) {
      return moment(search.createdDateMs).format('MM-DD-YYYY, h:mm:ss a')
    },
    deleteSavedSearch () {
      if (this.selectedSavedSearch === null) return

      const search = this.selectedSavedSearch
      const self = this
      this.$swal({
        icon: 'question',
        title: 'Delete Saved Search?',
        text: `Are you sure you want to delete the saved search "${search.queryName}"? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Delete'
      }).then(result => {
        if (result.isConfirmed) {
          self.selectedSavedSearch = null
          self.$store.commit('setSavedSearches', self.$store.state.savedSearches.filter(saved => saved.id !== search.id))
          const payload = {
            datasetCategory: search.datasetCategory,
            datasetId: search.datasetId,
            queryName: search.queryName
          }
          self.$store.dispatch('removeSavedQuery', payload).catch(error => {
            console.error('Error deleting saved search', error)
          })
        }
      })
    },
    loadSavedSearch () {
      if (this.selectedSavedSearch === null) return

      const search = this.selectedSavedSearch
      const searchQuery = JSON.parse(search.queryJson)

      // clear all filters then start adding new ones
      this.clearAllFilters()
      const filters = JSON.parse(JSON.stringify(this.datasetFilters))
      if (this.selectedDatasetDescription.filterSupport.supportsTimeRange && searchQuery.timeRange !== null) {
        const start = moment.utc(searchQuery.timeRange.start).milliseconds(0)
        const end = moment.utc(searchQuery.timeRange.end).milliseconds(0)
        filters.timeRange = {
          start,
          end,
          textual: `${start.format('MM/DD/YYYY, hh:mm:ss A')} - ${end.format('MM/DD/YYYY, hh:mm:ss A')}`
        }
      }

      if (this.selectedDatasetDescription.filterSupport.supportsGeospatial && searchQuery.geospatial !== null) {
        const polygonStrings = searchQuery.geospatial.polygons.match(/\(([^)]+)\)/g)
        const polygons = polygonStrings.map(polygonStr => {
          const allPoints = polygonStr.replaceAll(/\(|\)|,/g, '').split(' ')
          const points = Array.from({ length: allPoints.length / 2 }, (_, i) => [allPoints[2 * i + 1], allPoints[2 * i]]).map(point => {
            return { lat: point[0], lng: point[1] }
          })
          return {
            id: uuidv4(),
            points
          }
        })

        const pluralEnding = polygons.length === 1 ? '' : 's'
        const textual = `${polygons.length} Geospatial Filter${pluralEnding}`

        filters.geospatial = {
          textual,
          polygons
        }
      }

      // add remaining filters
      if (searchQuery.filters !== null) {
        for (const filter of searchQuery.filters) {
          const targetFilter = _.find(filters.additionalFilters, ['name', filter.name])
          if (typeof targetFilter !== 'undefined') {
            targetFilter.value = filter.value
            filters.fieldFilters.push(targetFilter)
            filters.additionalFilters = filters.additionalFilters.filter(addtlFilter => addtlFilter.name !== filter.name)
          }
        }
      }

      this.$store.commit('setDatasetFilters', filters)
      this.isSelectingSavedSearch = false
    },
    async onRetryLoadDatasetDescriptions (category, dataset) {
      await this.fetchDatasetDescription(category, dataset)
      this.setFilters()
    },
    async fetchDatasetDescription (category, dataset) {
      this.isLoadingDescription = true
      this.failureLoadingDescription = false
      try {
        const response = await this.$store.dispatch('fetchUnifiedDatasetDescription', { category: category.id, datasetId: dataset.id })
        this.$store.commit('setSourceDescriptions', { id: `${category.id}/${dataset.id}`, description: response.data })
      } catch (error) {
        console.error('Error fetching dataset description', error)
        this.failureLoadingDescription = true
      } finally {
        this.isLoadingDescription = false
      }
    },
    removeAdditionalFilter (filter) {
      if (filter.required) return
      this.datasetFilters.fieldFilters = this.datasetFilters.fieldFilters.filter(f => f.name !== filter.name)
      filter.value = null
      this.datasetFilters.additionalFilters.push(filter)
      this.datasetFilters.additionalFilters = _.sortBy(this.datasetFilters.additionalFilters, 'display')
    },
    clearAllFilters () {
      this.$store.commit('setDatasetFilters', { fieldFilters: [], additionalFilters: [] })
      this.setFilters()
    },
    setFilters () {
      if (this.selectedDatasetDescription === null) return
      const existingFilters = JSON.parse(JSON.stringify(this.datasetFilters))
      if (this.datasetFilters !== null) {
        existingFilters.fieldFilters = existingFilters.fieldFilters.filter(f => !f.preFilled) // don't use prefilled filters when mapping values over
      }

      // copy so the description doesn't get changed
      const filterSupport = JSON.parse(JSON.stringify(this.selectedDatasetDescription.filterSupport))
      const filters = { fieldFilters: [], additionalFilters: [] }

      // time range
      if (filterSupport.supportsTimeRange) {
        filters.timeRange = null
        if (existingFilters !== null && typeof existingFilters.timeRange !== 'undefined' && existingFilters.timeRange !== null) {
          filters.timeRange = {
            start: moment(existingFilters.timeRange.start),
            end: moment(existingFilters.timeRange.end),
            textual: existingFilters.timeRange.textual
          }
        }
      }

      // geospatial
      if (filterSupport.supportsGeospatial) {
        filters.geospatial = null
        if (existingFilters !== null && typeof existingFilters.geospatial !== 'undefined' && existingFilters.geospatial !== null) {
          filters.geospatial = existingFilters.geospatial
        }
      }

      if (filterSupport.preFilledFilters !== null) {
        const sorted = _.sortBy(filterSupport.preFilledFilters, 'name')
        for (const filter of sorted) {
          filters.fieldFilters.push({
            description: null,
            display: this.deSnakeCase(filter.name),
            name: filter.name,
            required: true,
            type: 'STRING',
            value: filter.value,
            preFilled: true
          })
        }
      }

      if (filterSupport.staticFilters !== null) {
        // required filters
        const sorted = _.sortBy(filterSupport.staticFilters, 'display')
        for (const filter of sorted) {
          if (!filter.required) continue
          filter.value = null
          filter.preFilled = false
          if (existingFilters !== null) {
            filter.value = existingFilters.fieldFilters.find(f => f.name === filter.name)?.value ?? null
          }
          filters.fieldFilters.push(filter)
        }

        // additional filters
        for (const filter of sorted) {
          if (filter.required) continue
          filter.value = null
          filter.preFilled = false
          if (existingFilters !== null) {
            filter.value = existingFilters.fieldFilters.find(f => f.name === filter.name)?.value ?? null
          }

          if (filter.value !== null && filter.value.toString().length > 0) {
            filters.fieldFilters.push(filter)
          } else {
            filters.additionalFilters.push(filter)
          }
        }
      }

      this.$store.commit('setDatasetFilters', filters)
    },
    performSearch (isSample) {
      const id = uuidv4()

      let filters = null
      if (!isSample) {
        filters = {
          timeRange: null,
          geospatial: JSON.parse(JSON.stringify((typeof this.datasetFilters.geospatial !== 'undefined') ? this.datasetFilters.geospatial : null)),
          dynamic: JSON.parse(JSON.stringify(this.datasetFilters.fieldFilters.filter(filter => filter.value !== null && filter.value.toString().length > 0)))
        }

        if (typeof this.datasetFilters.timeRange !== 'undefined' && this.datasetFilters.timeRange !== null) {
          filters.timeRange = {
            start: this.datasetFilters.timeRange.start.clone(),
            end: this.datasetFilters.timeRange.end.clone(),
            textual: JSON.parse(JSON.stringify(this.datasetFilters.timeRange.textual))
          }
        }
      }

      const search = {
        id,
        filters,
        isSample,
        isSearching: false,
        searchError: false,
        timestamp: Date.now(),
        datasetDescription: this.selectedDatasetDescription,
        results: null,
        totalResultCount: null,
        currentOffset: 0,
        visibleColumns: null,
        viewMode: SearchResultViewMode.TABLE,
        mapResults: {
          items: null,
          itemFilter: '',
          loadingData: false,
          errorLoading: false,
          endTimeMs: null,
          mapCenter: null,
          mapZoom: null
        }
      }
      this.$store.commit('addDatasetSearch', search)
      this.$router.push({
        name: 'SearchResults',
        params: { searchId: id }
      })
    }
  },
  mounted () {
    this.setFilters()
  }
}
</script>
